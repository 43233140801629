import React, { useState } from 'react'
import { Formik } from 'formik'
import { Link, useLocation } from 'react-router-dom'
import CarbonFootprintLogo from '../../../assets/images/truck-scale-carbon-footprint.png'
import { TruckScaleCarbonFootprintValidation } from '../../../components/Forms/Validation'
import { ScaleToolsNumberInput } from '../../../components/Forms/ScaleToolsTextInput'
import calculators from '../calculators'

const superDuperSecretValue = 0.000177777
const sortaSecretValue = 10180
const smallSecretValue = 0.001
const weeksInAYear = 52

const calculator = calculators.find(x => x.slug === 'carbon-footprint')

const CarbonFootprintCalculator = () => {
    const {
        state = {
            averageTimeOnScaleInSeconds: 0,
            costOfDieselFuelPerGallon: 0,
            daysOfOperationPerWeek: 0,
            gallonsOfFuelBurnedAtIdlePerYear: 0,
            numberOfTrucksPerDay: 0,
            potentialCarbonFootprintReductionInKilogramsOfCo2: 0,
            valueOfWastedDieselFuel: 0
        }
    } = useLocation()

    const [gallonsOfFuelBurnedAtIdlePerYear, setGallonsOfFuelBurnedAtIdlePerYear] = useState(
        state.gallonsOfFuelBurnedAtIdlePerYear
    )
    const [valueOfWastedDieselFuel, setValueOfWastedDieselFuel] = useState(
        state.valueOfWastedDieselFuel
    )
    const [
        potentialCarbonFootprintReductionInKilogramsOfCo2,
        setPotentialCarbonFootprintReductionInKilogramsOfCo2
    ] = useState(state.potentialCarbonFootprintReductionInKilogramsOfCo2)

    const gallonsOfFuelBurnedAtIdlePerYearDisplay = gallonsOfFuelBurnedAtIdlePerYear.toFixed(1)
    const valueOfWastedDieselFuelDisplay = parseFloat(
        valueOfWastedDieselFuel.toFixed(2)
    ).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD' // Change 'USD' to your local currency if needed
    })
    const potentialCarbonFootprintReductionInKilogramsOfCo2Display = parseFloat(
        potentialCarbonFootprintReductionInKilogramsOfCo2.toFixed(2)
    ).toLocaleString()

    const onCalculate = values => {
        const gallonsBurned =
            values.daysOfOperationPerWeek *
            weeksInAYear *
            values.numberOfTrucksPerDay *
            values.averageTimeOnScaleInSeconds *
            superDuperSecretValue

        setGallonsOfFuelBurnedAtIdlePerYear(gallonsBurned)
        setValueOfWastedDieselFuel(values.costOfDieselFuelPerGallon * gallonsBurned)
        setPotentialCarbonFootprintReductionInKilogramsOfCo2(
            gallonsBurned * sortaSecretValue * smallSecretValue
        )
    }

    const onReset = () => {
        setGallonsOfFuelBurnedAtIdlePerYear(0)
        setValueOfWastedDieselFuel(0)
        setPotentialCarbonFootprintReductionInKilogramsOfCo2(0)
    }

    return (
        <div id="carbon-footprint-calculator">
            <div id="carbon-footprint-container">
                <div id="header">
                    <img src={CarbonFootprintLogo} alt="Carbon Footprint Logo" />
                    <p style={{ marginLeft: '10px' }}>{calculator.formDescription}</p>
                </div>

                <Formik
                    initialValues={{ ...state }}
                    onSubmit={onCalculate}
                    validationSchema={TruckScaleCarbonFootprintValidation}
                >
                    {({ handleSubmit, isValid, resetForm, values }) => {
                        return (
                            <>
                                <form name="form" onSubmit={handleSubmit}>
                                    <div className="form-field">
                                        <ScaleToolsNumberInput
                                            label="Average Time on Scale in Seconds - Static Weighing"
                                            name="averageTimeOnScaleInSeconds"
                                            onFocus={e => e.target.select()}
                                            showErrorText
                                        />
                                    </div>

                                    <div className="form-field">
                                        <ScaleToolsNumberInput
                                            label="Number of Trucks per Day"
                                            name="numberOfTrucksPerDay"
                                            onFocus={e => e.target.select()}
                                            showErrorText
                                        />
                                    </div>
                                </form>
                                <form name="form">
                                    <div className="form-field">
                                        <ScaleToolsNumberInput
                                            label="Days of Operation per Week"
                                            name="daysOfOperationPerWeek"
                                            onFocus={e => e.target.select()}
                                            showErrorText
                                        />
                                    </div>

                                    <div className="form-field">
                                        <ScaleToolsNumberInput
                                            label="Cost of Diesel Fuel per Gallon"
                                            name="costOfDieselFuelPerGallon"
                                            onFocus={e => e.target.select()}
                                            showErrorText
                                        />
                                    </div>
                                </form>

                                <div>
                                    <button
                                        disabled={!isValid}
                                        style={{ paddingBottom: 14 }}
                                        type="submit"
                                        onClick={handleSubmit}
                                    >
                                        Calculate
                                    </button>
                                </div>

                                <div style={{ marginLeft: 2, marginRight: 2 }}>
                                    <button
                                        style={{
                                            borderStyle: 'solid',
                                            borderWidth: 2,
                                            borderColor: 'gray'
                                        }}
                                        type="button"
                                        onClick={() => {
                                            onReset()
                                            resetForm()
                                        }}
                                        className="reset-btn"
                                    >
                                        Reset
                                    </button>
                                </div>

                                <Link
                                    className="btn btn-grey"
                                    to={{
                                        pathname: '/truck-scale/carbon-footprint/report',
                                        state: {
                                            averageTimeOnScaleInSeconds:
                                                values.averageTimeOnScaleInSeconds,
                                            costOfDieselFuelPerGallon:
                                                values.costOfDieselFuelPerGallon,
                                            daysOfOperationPerWeek: values.daysOfOperationPerWeek,
                                            gallonsOfFuelBurnedAtIdlePerYear,
                                            gallonsOfFuelBurnedAtIdlePerYearDisplay,
                                            numberOfTrucksPerDay: values.numberOfTrucksPerDay,
                                            potentialCarbonFootprintReductionInKilogramsOfCo2,
                                            potentialCarbonFootprintReductionInKilogramsOfCo2Display,
                                            valueOfWastedDieselFuel,
                                            valueOfWastedDieselFuelDisplay
                                        }
                                    }}
                                >
                                    View Report
                                </Link>
                            </>
                        )
                    }}
                </Formik>
            </div>

            <div id="result">
                <h3>Result</h3>

                <div id="results">
                    <div className="container">
                        <div>
                            <h4>Gallons of Fuel Burned at Idle per Year</h4>
                        </div>
                        <h2>{gallonsOfFuelBurnedAtIdlePerYearDisplay} gal</h2>
                    </div>

                    <div className="container">
                        <div>
                            <h4>Value of Wasted Diesel Fuel per Year</h4>
                        </div>
                        <h2>{valueOfWastedDieselFuelDisplay}</h2>
                    </div>

                    <div className="container">
                        <div>
                            <h4>
                                Potential Carbon Footprint Reduction in Kilograms of CO2 per Year
                            </h4>
                        </div>
                        <h2 className="carbon-reduction">
                            {potentialCarbonFootprintReductionInKilogramsOfCo2Display} kg
                        </h2>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CarbonFootprintCalculator
