import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import calculators from '../calculators'
import ReportTable from './ReportTable'

const calculator = calculators.find(x => x.slug === 'maximum-speed')

const MaximumSpeedReport = () => {
    const {
        state = {
            maxSpeed: 0,
            minimumTimeOnScaleInSeconds: 1.5,
            scaleLength: 0,
            wheelbase: 0
        }
    } = useLocation()

    const onPrint = () => {
        window.print()
    }

    return (
        <div>
            <h1>{calculator.title}</h1>
            <div className="button-container">
                <button className="no-print" onClick={onPrint} type="button">
                    Print or Save PDF
                </button>
            </div>

            <ReportTable>
                <tr>
                    <td className="input-column">Vehicle Wheelbase</td>
                    <td className="results-column">{state.wheelbase} feet</td>
                </tr>
                <tr>
                    <td>Scale Length</td>
                    <td>{state.scaleLength} feet</td>
                </tr>
                <tr>
                    <td>Minimum Time on Scale</td>
                    <td>{state.minimumTimeOnScaleInSeconds} seconds</td>
                </tr>
                <tr>
                    <td>Maximum Speed</td>
                    <td>{state.maxSpeed} miles per hour</td>
                </tr>
            </ReportTable>

            <p>
                To ensure Legal for Trade accuracy, the maximum vehicle speed on SURVIVOR OTR-IMS
                scales cannot exceed 6 miles/hour.
            </p>

            <div className="button-container">
                <Link
                    className="btn btn-grey no-print"
                    to={{
                        pathname: '/truck-scale/maximum-speed',
                        state: {
                            maxSpeed: Number(state.maxSpeed),
                            scaleLength: state.scaleLength,
                            wheelbase: state.wheelbase
                        }
                    }}
                >
                    Return to Inputs
                </Link>
            </div>
        </div>
    )
}

export default MaximumSpeedReport
